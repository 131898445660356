import React from 'react';
import {StyleSheet} from 'react-native';

import {InputToolbar, InputToolbarProps} from 'react-native-gifted-chat';

type TTextInputProps = {
  props: InputToolbarProps;
};

const TextInput = ({props}: TTextInputProps) => {
  return <InputToolbar {...props} containerStyle={styles.container} />;
};

const styles = StyleSheet.create({
  container: {
    borderTopColor: '#dcdcdc',
  },
});

export default TextInput;
