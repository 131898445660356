import api from './api';
import {TChatType} from 'pages/Chat';

const namespace = 'chat';

type TDataProps = {
  client_id: number;
  merchant_id: number;
  order_id: number;
};

type TConnectionProps = {
  data: TDataProps;
  type: TChatType;
  socketId: string;
};

export default class Chat {
  static async establishConnection({data, type, socketId}: TConnectionProps) {
    const isClient = type === 'client';
    const url = isClient ? 'client/merchant' : 'merchant/client';
    const extraData = isClient
      ? {client_socket_id: socketId}
      : {merchant_socket_id: socketId};

    return api
      .post(`${namespace}/${url}/establish-connection`, {
        ...data,
        ...extraData,
      })
      .then(async response => {
        if (response.status) {
          return {
            data: response.data,
            ok: true,
          };
        }
        return {
          ok: false,
          data: {},
          message: response?.data.message || 'Erro',
        };
      })
      .catch(error => {
        return {
          ok: false,
          data: error.response?.data,
          message: error.response?.data.message || 'Erro',
        };
      });
  }
}
