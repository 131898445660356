import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {Send, SendProps} from 'react-native-gifted-chat';

type TButtonSendProps = {
  props: SendProps &
    Readonly<{
      children?: React.ReactNode;
    }>;
};

const ButtonSend = ({props}: TButtonSendProps) => {
  return (
    <Send {...props} containerStyle={styles.send}>
      <View style={styles.view}>
        <Text style={styles.text}>Enviar</Text>
      </View>
    </Send>
  );
};

const styles = StyleSheet.create({
  send: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
  },
  view: {
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 20,
  },
  text: {
    color: '#ff3333',
    fontSize: 14,
    fontWeight: 'bold',
    fontFamily: 'Nunito',
  },
});

export default ButtonSend;
