import api from './api';

const namespace = 'merchant/v1';

export default class Merchant {
  static async chatInfo(order_id) {
    return api
      .get(`${namespace}/order/chat-info/${order_id}`)
      .then(async response => {
        if (response.status) {
          return {
            data: response.data,
            ok: true,
          };
        }
        return {
          ok: false,
          data: {},
          message: response.data?.message || 'Erro',
        };
      })
      .catch(error => ({
        ok: false,
        data: error.response?.data,
        message: error.response?.message || 'Erro',
      }));
  }
}
