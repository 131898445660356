import React from 'react';
import {StyleSheet, View} from 'react-native';

import Icon from 'react-native-vector-icons/dist/FontAwesome';

const ButtonScrollBottom = () => {
  return (
    <View style={styles.scroll}>
      <Icon name="chevron-down" size={20} color="#ff3333" />
    </View>
  );
};

const styles = StyleSheet.create({
  scroll: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: 40,
    height: 40,
    borderRadius: 75,
    backgroundColor: '#fff',
  },
});

export default ButtonScrollBottom;
