import React from 'react';

import {
  Bubble as Container,
  BubbleProps,
  IMessage,
} from 'react-native-gifted-chat';

type TBubbleProps = {
  props: BubbleProps<IMessage> &
    Readonly<{
      children?: React.ReactNode;
    }>;
};

const Bubble = ({props}: TBubbleProps) => {
  return (
    <Container
      {...props}
      wrapperStyle={{
        left: props.containerStyle?.left,
        right: {
          backgroundColor: '#ff3333',
        },
      }}
    />
  );
};

export default Bubble;
