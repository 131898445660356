import api from './api';

const namespace = '/order/v2';

export default class Order {
  static async chatInfo(order_id: string) {
    return api
      .get(`${namespace}/orders/${order_id}/chat-info`)
      .then(async response => {
        if (response.status) {
          return {
            data: response.data,
            ok: true,
          };
        }
        return {
          ok: false,
          data: {},
          message: response.data?.message || 'Erro',
        };
      })
      .catch(error => ({
        ok: false,
        data: error.response?.data,
        message: error.response?.message || 'Erro',
      }));
  }
}
