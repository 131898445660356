import React from 'react';
import {StyleSheet, Text, View} from 'react-native';

type TTextCounterProps = {
  length: number;
};

const TextCounter = ({length}: TTextCounterProps) => {
  return (
    <View style={styles.viewTextCounter}>
      <Text style={styles.textCounter}>{length}/400</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  viewTextCounter: {
    marginBottom: 5,
    marginLeft: 2,
  },
  textCounter: {
    fontSize: 12,
    color: '#a3a3a3',
    fontFamily: 'Nunito',
  },
});

export default TextCounter;
