import axios from 'axios';

import {getQueryVariable} from '../utils/queryParam';

const api = axios.create({
  baseURL: process.env.REACT_APP_CHAT_GATEWAY,
  headers: {
    'X-Gateway-Token': process.env.REACT_APP_X_GATEWAY_TOKEN,
    'X-App-Version': process.env.REACT_APP_X_APP_VERSION,
  },
});

api.interceptors.request.use(async config => {
  const TOKEN = getQueryVariable('token');

  if (TOKEN) {
    config.headers.Authorization = TOKEN;
  }

  return config;
});

export default api;
