import React from 'react';
import {StyleSheet, Platform} from 'react-native';

import {
  Composer as Container,
  ComposerProps,
  SendProps,
} from 'react-native-gifted-chat';

type TComposerProps = {
  props: ComposerProps &
    Readonly<{
      onSend?: SendProps['onSend'];
      text?: SendProps['text'];
      children?: React.ReactNode;
    }>;
};

const Composer = ({props}: TComposerProps) => {
  return (
    <Container
      {...props}
      textInputStyle={styles.inputContainer}
      textInputProps={{
        ...props.textInputProps,
        blurOnSubmit: Platform.OS === 'web',
        onSubmitEditing:
          Platform.OS === 'web'
            ? () => {
                if (props.text && props.onSend) {
                  props.onSend({text: props.text.trim()}, true);
                }
              }
            : undefined,
      }}
    />
  );
};

const styles = StyleSheet.create({
  inputContainer: {
    width: '100%',
  },
});

export default Composer;
